// /* global Blob */
// /* global fetch */

import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import client from '../../client'

export default function Controls () {
  const { idParam } = useParams()

  const [mjpegImgUrl, setMjpegImgUrl] = useState(null)
  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get(`/cameras/${idParam}`)
        .then(response => response.data)
        .then(data => {
          setMjpegImgUrl('http://admin:3xpl0r3!@38.104.83.75:5080/cgi-bin/mjpg/video.cgi?channel=0&subtype=1')
          const mjpegUrl = data.controls.mjpeg_source
          console.log(mjpegUrl)
        })
    }

    return () => { mounted = false }
  }, [idParam])

  const [camera, setCamera] = useState(null)
  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get(`/cameras/${idParam}`)
        .then(response => response.data)
        .then(data => {
          if (data?.camera) {
            console.log('camera', data.camera)
            setCamera(data.camera)
          }
        })
    }

    return () => { mounted = false }
  }, [idParam])

  if (!camera) {
    return (
      <div>Not found</div>
    )
  }

  return (
    <div>
      <h2>{camera.name}</h2>
      <div>
        <img src={mjpegImgUrl} type='image/jpeg' alt='mjpeg video preview' />
      </div>
      <div>
        up down left right<br />
        x y
      </div>
      <div>
        zoom in zoom out<br />
        zoom %
      </div>
    </div>
  )
}
