import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { config } from '../../config'

export default function Login ({ setToken }) {
  const [email, setEmail] = useState()
  const [password, setPassword] = useState()

  const handleSubmit = async (e) => {
    e.preventDefault()
    const token = await authenticate({ email, password })
    if (token) {
      setToken(token)
    }
  }

  return (
    <div className='login-wrapper'>
      <h1>Log in</h1>
      <form onSubmit={handleSubmit}>
        <div className='field'>
          <label>
            <div>Email</div>
            <input type='text' name='email' onChange={e => setEmail(e.target.value)} />
          </label>
        </div>
        <div className='field'>
          <label>
            <div>Password</div>
            <input type='password' name='password' onChange={e => setPassword(e.target.value)} />
          </label>
        </div>
        <div className='field'>
          <button type='submit'>Log in</button>
        </div>
      </form>
    </div>
  )
}

async function authenticate (credentials) {
  return fetch(
    `${config.baseURL}/api/authenticate`,
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(credentials)
    }
  )
    .then(res => {
      if (res.status !== 200) {
        throw new Error('invalid response')
      }
      return res
    })
    .then(data => data.json())
    .catch(_err => {
      console.log('could not authenticate')
    })
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
