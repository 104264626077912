import { useState, useEffect, useLayoutEffect } from 'react'
import { Link } from 'react-router-dom'
import Hls from 'hls.js'
import client from '../../client'

export default function StreamIndex () {
  const [streams, setStreams] = useState([])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/streams')
        .then(response => response.data)
        .then(data => {
          if (data?.streams) {
            setStreams(data.streams)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  useLayoutEffect(() => {
    let mounted = true

    if (mounted) {
      if (Hls.isSupported()) {
        streams.forEach(function (stream) {
          const video = document.getElementById(`video-stream-${stream.id}`)
          const hls = new Hls()
          hls.loadSource(stream.urls.playlist_url)
          hls.attachMedia(video)
        })
      }
    }

    return () => { mounted = false }
  }, [streams])

  return (
    <div className='wrapper streams-wrapper'>
      <h2>Streams</h2>

      <section className='search stream-search'>
        <input className='search-field' type='text' name='search' id='cameras-list-search' />
      </section>

      <section className='filters stream-filters'>
        <a className='filter-button' href='/streams?group=all'>All</a>
        <a className='filter-button' href='/streams?group=my-cameras'>My cameras</a>
        <a className='filter-button' href='/streams?stream_type=any'>Any</a>
        <a className='filter-button' href='/streams?stream_type=live'>Live</a>
        <a className='filter-button' href='/streams?stream_type=highlights'>Highlights</a>
      </section>

      <section className='streams-section'>
        {streams.map(function (stream) {
          return (
            <div key={stream.id} className='stream'>
              <div>
                <span className='name'>{stream.name}</span>
                <span className='link'>
                  <Link to={`/cameras/${stream.camera.id}`}>Info</Link>
                </span>
                <span className='link'>
                  <Link to={`/cameras/${stream.camera.id}/controls`}>Controls</Link>
                </span>
              </div>
              <video id={`video-stream-${stream.id}`} class='stream-video-preview' />
            </div>
          )
        })}
      </section>
    </div>
  )
}
