import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import client from '../../client'

export default function CameraIndex () {
  const [cameras, setCameras] = useState([])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/cameras')
        .then(response => response.data)
        .then(data => {
          if (data?.cameras) {
            setCameras(data.cameras)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  return (
    <div className='wrapper cameras-wrapper'>
      <h2>Cameras</h2>

      <section className='camera-filters'>
        <a className='filter-button' href='/cameras?group=all'>All</a>
        <a className='filter-button' href='/cameras?group=my-cameras'>My cameras</a>
        <a className='filter-button' href='/cameras?order_name=asc'><small>ASC</small></a>
        <a className='filter-button' href='/cameras?order_name=desc'><small>DESC</small></a>
      </section>

      <section className='cameras-section'>
        {cameras.map(function (camera) {
          return (
            <div key={camera.id} className='camera'>
              <span className='name'>{camera.name}</span>
              <span className='link'>
                <Link to={`/cameras/${camera.id}`}>Info</Link>
              </span>
              <span className='link'>
                <Link to={`/cameras/${camera.id}/controls`}>Controls</Link>
              </span>
            </div>
          )
        })}
      </section>
    </div>
  )
}
