import { useState, useEffect } from 'react'
import client from '../../client'

export default function EditProfile () {
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [username, setUsername] = useState(null)
  const [phone, setPhone] = useState(null)

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/profile')
        .then(response => response.data)
        .then(data => {
          if (data?.user) {
            setName(data.user.name)
            setEmail(data.user.email)
            setUsername(data.user.username)
            setPhone(data.user.phone)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  return (
    <div className='wrapper profile-wrapper'>
      <h2>Profile</h2>

      <div className='alert alert-warning'>
        Cannot be updated
      </div>

      <section>
        <h3>Details</h3>
        <div className='form-field'>
          <div className='label'>
            <label>Name</label>
          </div>
          <div className='value'>
            <input type='text' value={name} disabled='disabled' />
          </div>
        </div>
        <div className='form-field'>
          <div className='label'>
            <label>Email</label>
          </div>
          <div className='value'>
            <input type='text' value={email} disabled='disabled' />
          </div>
        </div>
        <div className='form-field'>
          <div className='label'>
            Phone
          </div>
          <div className='value'>
            <input type='text' value={phone} disabled='disabled' />
          </div>
        </div>
      </section>

      <section>
        <h3>Explore.org</h3>
        <div className='form-field'>
          <div className='label'>
            Username
          </div>
          <div className='value'>
            <input type='text' value={username} disabled='disabled' />
          </div>
        </div>
      </section>

      <div className='actions'>
        <a href='/profile' className='action cancel-action'>Cancel</a>
        <a href='/profile' className='action save-action'>Save</a>
      </div>
    </div>
  )
}
