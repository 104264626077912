import { BrowserRouter, Routes, Route, Link, Outlet } from 'react-router-dom'
import useToken from './useToken'

import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import Login from '../Session/Login'
import Logout from '../Session/Logout'
import Dashboard from '../Dashboard/Dashboard'
import Preferences from '../Preferences/Preferences'
import EditPreferences from '../Preferences/EditPreferences'
import Profile from '../Profile/Profile'
import EditProfile from '../Profile/EditProfile'
import Camera from '../Camera/Camera'
import CameraIndex from '../Camera/CameraIndex'
import StreamIndex from '../Stream/StreamIndex'
import Controls from '../Camera/Controls'
import MultiviewIndex from '../Multiview/MultiviewIndex'

if (process.env.SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  })
}

function App () {
  const { token, setToken, deleteToken } = useToken()

  if (!token) {
    return <Login setToken={setToken} />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='multiview' element={<MultiviewIndex />} />
        <Route path='cameras/:idParam/controls' element={<Controls />} />
        <Route path='/' element={<Layout deleteToken={deleteToken} />}>
          <Route path='dashboard' element={<Dashboard />} index />
          <Route path='preferences' element={<Preferences />} />
          <Route path='preferences/edit' element={<EditPreferences />} />
          <Route path='profile' element={<Profile />} />
          <Route path='profile/edit' element={<EditProfile />} />

          <Route path='cameras' element={<CameraIndex />} />
          <Route path='cameras/:idParam' element={<Camera />} />
          <Route path='streams' element={<StreamIndex />} />
        </Route>
        <Route path='*' element={<NoMatch />} />
      </Routes>
    </BrowserRouter>
  )
}

function Layout ({ deleteToken }) {
  return (
    <div className='container'>
      <h1>Application</h1>
      <nav className='main-menu'>
        <ul>
          <li><Link to='/dashboard'>Dashboard</Link></li>
          <li><Link to='/cameras'>Cameras</Link></li>
          <li><Link to='/streams'>Streams</Link></li>
          <li><Link to='/preferences'>Preferences</Link></li>
          <li><Link to='/profile'>Profile</Link></li>
          <li><Logout deleteToken={deleteToken} /></li>
        </ul>
      </nav>
      <div className='content'>
        <Outlet />
      </div>
    </div>
  )
}

function NoMatch () {
  return (
    <div className='wrapper'>
      <h1>404 not found</h1>
      <div className='error'>
        404 not found
      </div>
    </div>
  )
}

export default App
