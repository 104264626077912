import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import client from '../../client'

export default function MultiviewIndex () {
  const [cameras, setCameras] = useState([])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/cameras')
        .then(response => response.data)
        .then(data => {
          if (data?.cameras) {
            setCameras(data.cameras)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  return (
    <div class='multiview'>
      {cameras.map(function (camera) {
        return (
          <Link to={`/cameras/${camera.id}/controls`} key={camera.id} className='camera'>
            <img src={camera.controls.direct_mjpeg_url} height={270} width={430} className='video' alt='video preview' />
          </Link>
        )
      })}
    </div>
  )
}
