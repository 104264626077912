import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import client from '../../client'

export default function Camera () {
  const [camera, setCamera] = useState(null)
  const [configuration, setconfiguration] = useState(null)
  const [stream, setstream] = useState(null)
  const { idParam } = useParams()

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get(`/cameras/${idParam}`)
        .then(response => response.data)
        .then(data => {
          if (data?.camera) {
            console.log('camera', data.camera)
            setCamera(data.camera)
          }
          if (data?.configuration) {
            console.log('configuration', data.configuration)
            setconfiguration(data.configuration)
          }
          if (data?.stream) {
            console.log('stream', data.stream)
            setstream(data.stream)
          }
        })
    }

    return () => { mounted = false }
  }, [idParam])

  if (!camera) {
    return (
      <div>Not found</div>
    )
  }

  let configurationDiv = <div>Configuration</div>

  if (!configuration) {
    configurationDiv = <div>The camera is missing a configuration therefore the controls view is not available.</div>
  }

  return (
    <div className='wrapper camera-wrapper'>
      <h2>Camera</h2>

      <div>
        <span className='name'>{camera.name}</span>
        <span className='hardware'>{camera.hardware_description}</span>
        {stream && (
          <span className='name'>{stream.name}</span>
        )}
        {configuration && (
          <span className='name'>{configuration.id}</span>
        )}
      </div>

      {configurationDiv}

      <section>
        <h3>Details</h3>
        <div className='field'>
          <div className='label'>
            Name
          </div>
          <div className='value'>
            {camera.name}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            ID
          </div>
          <div className='value'>
            #{camera.id}
          </div>
        </div>
      </section>
    </div>
  )
}
