import axios from 'axios'
import { config } from './config'

export default function client () {
  const token = localStorage.getItem('token')
  const instance = axios.create({
    baseURL: `${config.baseURL}/api/v1`,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Bearer ${token}`
    }
  })

  instance.interceptors.response.use((response) => {
    return response
  }, (error) => {
    console.log('ERROR', error)
    if (error.response.status >= 400 && error.response.status < 500) {
      localStorage.removeItem('token')
    }
  })

  return instance
}
