import { useState, useEffect } from 'react'
import Toggle from 'react-toggle'
import client from '../../client'
import { yesNo } from '../../utilities'

export default function Preferences () {
  const [theme, setTheme] = useState(null)
  const [timeZone, setTimeZone] = useState(null)
  const [ptzLocation, setPtzLocation] = useState(null)
  const [showCrosshair, setShowCrosshair] = useState(false)

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/preferences')
        .then(response => response.data)
        .then(data => {
          if (data?.preferences) {
            setTheme(data.preferences.theme)
            setTimeZone(data.preferences.time_zone)
            setPtzLocation(data.preferences.ptz_location)
            setShowCrosshair(data.preferences.show_crosshair)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  return (
    <div className='wrapper preferences-wrapper'>
      <h2>Preferences</h2>

      <section>
        <h3>Display</h3>
        <div className='field'>
          <div className='label'>
            Theme
          </div>
          <div className='value'>
            {theme}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            Time zone
          </div>
          <div className='value'>
            {timeZone}
          </div>
        </div>
      </section>

      <section>
        <h3>Controls</h3>
        <div className='field'>
          <div className='label'>
            PTZ location
          </div>
          <div className='value'>
            {ptzLocation}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            Show crosshair
          </div>
          <div className='value'>
            <label>
              <Toggle checked={showCrosshair} disabled />
              <span className='react-toggle-label'>{yesNo(showCrosshair)}</span>
            </label>
          </div>
        </div>
      </section>

      <div className='actions'>
        <a href='/preferences/edit' className='action edit-action'>Edit</a>
      </div>
    </div>
  )
}
