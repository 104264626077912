import { useState, useEffect } from 'react'
import Select from 'react-select'
import Toggle from 'react-toggle'
import client from '../../client'
import { yesNo } from '../../utilities'

export default function EditPreferences () {
  const [theme, setTheme] = useState(null)
  const [timeZone, setTimeZone] = useState(null)
  const [ptzLocation, setPtzLocation] = useState(null)
  const [showCrosshair, setShowCrosshair] = useState(false)

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/preferences')
        .then(response => response.data)
        .then(data => {
          if (data?.preferences) {
            setTheme(data.preferences.theme)
            setTimeZone(data.preferences.time_zone)
            setPtzLocation(data.preferences.ptz_location)
            setShowCrosshair(data.preferences.show_crosshair)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  const selectOptions = {
    ptzLocation: [
      { value: 'top-right', label: 'Top-right' },
      { value: 'bottom-right', label: 'Bottom-right' },
      { value: 'bottom-left', label: 'Bottom-left' },
      { value: 'top-right', label: 'Top-right' }
    ]
  }

  return (
    <div className='wrapper preferences-wrapper'>
      <h2>Edit preferences</h2>

      <section>
        <h3>Display</h3>
        <form>
          <div className='form-field'>
            <div className='label'>
              Theme
            </div>
            <div className='value'>
              <select name='theme' defaultValue={theme}>
                <option value='automatic'>Automatic</option>
                <option value='light'>Light</option>
                <option value='dark'>Dark</option>
              </select>
            </div>
          </div>
          <div className='form-field'>
            <div className='label'>
              Time zone
            </div>
            <div className='value'>
              <select name='timeZone' defaultValue={timeZone}>
                <option value='America/Los_Angeles'>America/Los_Angeles</option>
              </select>
            </div>
          </div>
        </form>
      </section>

      <section>
        <h3>Controls</h3>
        <div className='form-field'>
          <div className='label'>
            PTZ location
          </div>
          <div className='value'>
            <div style={{ display: 'none' }}>
              <Select name='ptzLocation' options={selectOptions.ptzLocation} defaultValue={ptzLocation} />
            </div>
            <select name='ptzLocation' defaultValue={ptzLocation}>
              <option value='top-right'>Top-right</option>
              <option value='bottom-right'>Bottom-right</option>
              <option value='bottom-left'>Bottom-left</option>
              <option value='top-right'>Top-right</option>
            </select>
          </div>
        </div>
        <div className='form-field checkbox-field'>
          <div className='label'>
            Show crosshair
          </div>
          <div className='value'>
            <label>
              <Toggle checked={showCrosshair} disabled />
              <span className='react-toggle-label'>{yesNo(showCrosshair)}</span>
            </label>
          </div>
        </div>
      </section>

      <div className='actions'>
        <a href='/preferences' className='action cancel-action'>Cancel</a>
        <a href='/preferences' className='action save-action'>Save</a>
      </div>
    </div>
  )
}
