import { useState } from 'react'

export default function useToken () {
  const getToken = () => {
    return localStorage.getItem('token')
  }

  const [token, setToken] = useState(getToken())

  const saveToken = (value) => {
    localStorage.setItem('token', value.token)
    setToken(value.token)
  }

  const removeToken = () => {
    localStorage.removeItem('token')
    setToken(null)
  }

  return {
    getToken,
    setToken: saveToken,
    deleteToken: removeToken,
    token
  }
}
