import { useState, useEffect } from 'react'
import client from '../../client'

export default function Profile () {
  function formatSignupDate (dateString) {
    const date = new Date(dateString)
    return date.toISOString().slice(0, 10)
  }

  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [username, setUsername] = useState(null)
  const [phone, setPhone] = useState(null)
  const [signupDate, setSignupDate] = useState(null)

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/profile')
        .then(response => response.data)
        .then(data => {
          if (data?.user) {
            setName(data.user.name)
            setEmail(data.user.email)
            setUsername(data.user.username)
            setPhone(data.user.phone)
            setSignupDate(formatSignupDate(data.user.signup_date))
          }
        })
    }

    return () => { mounted = false }
  }, [])

  return (
    <div className='wrapper profile-wrapper'>
      <h2>Profile</h2>

      <section>
        <h3>Details</h3>
        <div className='field'>
          <div className='label'>
            Name
          </div>
          <div className='value'>
            {name}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            Email
          </div>
          <div className='value'>
            {email}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            Username
          </div>
          <div className='value'>
            {username}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            Phone
          </div>
          <div className='value'>
            {phone}
          </div>
        </div>
        <div className='field'>
          <div className='label'>
            Signup date
          </div>
          <div className='value'>
            {signupDate}
          </div>
        </div>
      </section>
    </div>
  )
}
