import { useState, useEffect } from 'react'
import Toggle from 'react-toggle'
import client from '../../client'

export default function Dashboard () {
  const [cameras, setCameras] = useState([])

  useEffect(() => {
    let mounted = true

    if (mounted) {
      client()
        .get('/cameras')
        .then(response => response.data)
        .then(data => {
          if (data?.cameras) {
            setCameras(data.cameras)
          }
        })
    }

    return () => { mounted = false }
  }, [])

  return (
    <div className='wrapper dashboard-wrapper'>
      <h2>Dashboard</h2>

      <section className='cameras-section'>
        <h3>Recent cameras</h3>
        {cameras.map(function (camera) {
          return (
            <div key={camera.id} className='camera'>
              <label>
                <Toggle defaultChecked={false} />
                <span className='react-toggle-label'>{camera.name}</span>
              </label>
            </div>
          )
        })}
      </section>

      <div className='actions'>
        <a href='/multiview' className='action primary-action'>Launch multiview</a>
      </div>
    </div>
  )
}
