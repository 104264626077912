import PropTypes from 'prop-types'
import { config } from '../../config'

export default function Logout ({ deleteToken }) {
  const handleSubmit = async e => {
    e.preventDefault()
    destroySession()
  }

  function destroySession () {
    const token = localStorage.getItem('token')
    if (token) {
      deauthenticate({ token })
    }
    deleteToken()
  }

  return (
    <a href='/deauthenticate' onClick={handleSubmit}>Log out</a>
  )
}

async function deauthenticate (token) {
  return fetch(
    `${config.baseURL}/api/deauthenticate`,
    {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json'
      },
      body: JSON.stringify(token)
    }
  ).then(data => data.json())
}

Logout.propTypes = {
  deleteToken: PropTypes.func.isRequired
}
